import * as React from 'react';

import { Florida, Home, Layout, Shared } from '../../components';

const FloridaPage = () => {
  return (
    <>
      <Layout.NavMenu />
      <Florida.HeroImage />
      <Florida.PropertyTaxServices />
      <Home.WhyChooseUs />
      <Shared.WhyAppeal />
      <Shared.CaseStudiesResidential />
      <Shared.CaseStudiesCommercial />
      <Home.OurCompany />
      <Home.Contact />
      <Layout.Footer />
    </>
  );
};

export default FloridaPage;

export const Head = () => <Layout.PageHead subtitle="Florida" />;
